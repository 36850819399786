import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		// color: theme.workwaveBlue,
		border: '1px solid #E5EAF4',
		padding: '15px',
		borderRadius: '20px',
		minHeight: '350px',
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
		zIndex: 1000,
		// maxWidth: '289px',
		// position: 'relative',
		// paddingTop: '2.75rem',
		// [theme.breakpoints.down('xs')]: {
		// 	maxWidth: '100% !important',
		// 	marginLeft: '0px',
		// 	marginRight: '0px',
		// 	width: '100%',
		// },
		// [theme.breakpoints.down('sm')]: {
		// 	maxWidth: '263px',
		// },
		// [theme.breakpoints.down('md')]: {
		// 	maxWidth: '500px',
		// },
		// [theme.breakpoints.up('lg')]: {
		// 	maxWidth: '289px',
		// },
		// transition: 'transform 0.25s',
		// boxShadow:
		// 	'0 13px 27px -5px rgba(50, 50, 93, 0), 0 8px 16px -8px rgba(0, 0, 0, 0)',
	},
	title: {
		fontWeight: 700,
		[theme.breakpoints.down('xs')]: {
			fontSize: '1rem',
		},
	},
}));

export const FeatureCard = ({ feature }) => {
	const classes = useStyles();
	const { icon, title, body } = feature;
	return (
		<Grid container item md={6}>
			<Grid container className={classes.root}>
				<Grid
					item
					container
					direction='row'
					justifyContent='flex-start'
					alignItems='center'>
					<FontAwesomeIcon
						icon={['fad', icon]}
						style={{
							color: '#3FA529',
							height: '60px',
							width: '60px',
							border: `1px solid #E5EAF4`,
							borderRadius: '8px',
							background: '#E5EAF4',
							padding: '10px',
							textAlign: 'center',
							marginRight: '15px',
						}}
					/>{' '}
					<Typography variant='h6' color='primary' className={classes.title}>
						{title}
					</Typography>
				</Grid>
				<Typography variant='body1' style={{ lineHeight: '34px' }}>
					{body}
				</Typography>
			</Grid>
		</Grid>
	);
};
